import React, { memo } from 'react'
import { styled } from '@mui/material'
import { Swiper } from 'swiper/react'
import 'swiper/css'

interface Props {
  children: React.ReactNode
}

const Wrapper = styled('div')(({ theme }) => ({
  ['.swiper']: {
    overflow: 'visible',
  },
  ['.swiper-slide']: {
    width: 'auto',
    borderLeft: `1px solid ${theme.palette.outlineDark}`,
    ['&:first-of-type']: {
      borderLeft: 'none',
    },
  },
}))

const TabSwiper = ({ children }: Props) => {
  return (
    <Wrapper>
      <Swiper slidesPerView="auto" spaceBetween={0} freeMode>
        {children}
      </Swiper>
    </Wrapper>
  )
}

export default memo(TabSwiper)
