import React, { useState, memo } from 'react'
import { Container } from '@mui/material'

import Section from '_core/components/atoms/layout/Section'

import StatsTabs from 'public/components/molecules/sections/region-stats/StatsTabs'
import StatsRow from 'public/components/molecules/sections/region-stats/StatsRow'

interface Props {
  stats: Queries.DatoCmsStatistic
}

const RegionStats = ({ stats }: Props) => {
  const [value, setValue] = useState(0)

  function handleTabChange(event, newValue) {
    setValue(newValue)
  }
  function handleSelectChange(event) {
    setValue(parseInt(event.target.value))
  }

  const global = stats.globalStatistics[0]
  const regions = stats.regions

  return (
    <Section overflowXHidden vSpace>
      <Container maxWidth="xl">
        <StatsTabs
          regions={regions}
          handleTabChange={handleTabChange}
          handleSelectChange={handleSelectChange}
          value={value}
        />
      </Container>
      <Container maxWidth="xl">
        {value === 0 && (
          <StatsRow
            key="RegionStats-row-global"
            id="RegionStats-row-global"
            placements={global.placements}
            coverage={global.coverage}
            professionals={global.professionals}
            dynamic={global.regionsCovered}
            dynamicLabel="Regions Covered"
          />
        )}
        {regions.map(
          (item: Queries.DatoCmsTermRegion, index) =>
            value === index + 1 && (
              <StatsRow
                key={`RegionStats-row-${item.originalId}`}
                id={`RegionStats-row-${item.originalId}`}
                placements={item.placements}
                coverage={item.yearsOfCoverage}
                professionals={item.professionals}
                dynamic={item.officerHires}
              />
            )
        )}
      </Container>
    </Section>
  )
}

export default memo(RegionStats)
