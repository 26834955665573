import React, { useState, memo } from 'react'
import { Box, Divider, Grid, styled } from '@mui/material'
import { InView } from 'react-intersection-observer'
import CountUp from 'react-countup'

import Flex from '_core/components/atoms/layout/Flex'

interface Props {
  value: number
  label: string
}

const StatBoxWrapper = styled('div')(({ theme }) => ({
  border: `7px solid ${theme.palette.outlineDark}`,
  textAlign: 'center',
  padding: '30px',
  minHeight: '100px',
}))

const Value = styled(CountUp)(({ theme }) => ({
  display: 'block',
  fontFamily: theme.typography.fontFamilySerif,
  fontSize: '5.5rem',
}))

const Label = styled('span')(({ theme }) => ({
  display: 'block',
  textTransform: 'uppercase',
  fontSize: '1.2rem',
}))

const StatBox = ({ value, label }: Props) => {
  const [visible, setVisible] = useState(false)

  const statNumber = visible ? value : 0

  const Content = () => (
    <Flex center full>
      <Flex flexDirection="column" center>
        {visible ? (
          <Value end={statNumber} separator="," duration={1} />
        ) : (
          <Box
            sx={{
              fontSize: '5.5rem',
            }}
          >
            0
          </Box>
        )}

        <Label>{label}</Label>
        <Divider
          sx={{
            width: '1.5rem',
            backgroundColor: 'primary.main',
            mt: 1,
          }}
        />
      </Flex>
    </Flex>
  )

  return (
    <Grid item xs={12} sm={6} lg={3}>
      <InView
        as="div"
        rootMargin="-50px"
        onChange={(inView) => {
          if (inView) {
            setVisible(true)
          } else {
            setVisible(false)
          }
        }}
      >
        <StatBoxWrapper>
          <Content />
        </StatBoxWrapper>
      </InView>
    </Grid>
  )
}

export default memo(StatBox)
