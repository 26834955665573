import React, { memo } from 'react'
import { Box, Tabs, Tab, styled, useMediaQuery, useTheme } from '@mui/material'
import { SwiperSlide } from 'swiper/react'

import Select from '_core/components/atoms/inputs/Select'
import TabSwiper from 'public/components/molecules/swipers/TabSwiper'

interface Props {
  regions: Queries.DatoCmsTermRegion[]
  handleTabChange: () => void
  handleSelectChange: () => void
  value: string
}
const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  ['.swiper-container']: {
    overflow: 'visible',
  },
  ['.swiper-slide']: {
    minWidth: '100%',
    ['.MuiTab-root']: {
      paddingLeft: '30px',
      paddingRight: '30px',
      ['.MuiTab-wrapper']: {
        whiteSpace: 'nowrap',
      },
    },
  },
}))

const StatsTabs = ({
  regions,
  handleTabChange,
  handleSelectChange,
  value,
}: Props) => {
  const regionNames = regions.map((item) => item.region.name)

  let selectItems = []
  selectItems.push({
    name: 'Global',
    value: '0',
  })

  regions.map((item, index) => {
    selectItems.push({
      value: `${index + 1}`,
      name: item.region.name,
    })
  })

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Wrapper mb={{ xs: 2, sm: 3, xl: 4 }}>
      {mdDown ? (
        <Select
          name="stats"
          inputProps={{
            name: 'stats',
          }}
          items={selectItems}
          onChange={handleSelectChange}
          value={value}
          fullWidth
        />
      ) : (
        <TabSwiper>
          <SwiperSlide>
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor="primary"
              variant="fullWidth"
            >
              <Tab
                key="stats-tab-global"
                label="Global"
                disableRipple
                onClick={(e) => {
                  e.preventDefault()
                  if (typeof window !== 'undefined') {
                    window.gtag('event', 'stats_tab_clicked', {
                      action: 'Stats Tab Clicked',
                      tab: 'global',
                    })
                  }
                }}
              />
              {regionNames.map((item, key) => (
                <Tab
                  key={key}
                  label={item}
                  disableRipple
                  onClick={(e) => {
                    e.preventDefault()
                    if (typeof window !== 'undefined') {
                      window.gtag('event', 'stats_tab_clicked', {
                        action: 'Stats Tab Clicked',
                        tab: item,
                      })
                    }
                  }}
                />
              ))}
            </Tabs>
          </SwiperSlide>
        </TabSwiper>
      )}
    </Wrapper>
  )
}

export default memo(StatsTabs)
