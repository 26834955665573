import React, { memo } from 'react'
import { Grid, styled } from '@mui/material'
import StatBox from 'public/components/molecules/sections/region-stats/StatBox'

interface Props {
  keyId: string
  placements: number
  professionals: number
  coverage: number
  dynamic: number
  dynamicLabel?: string
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    width: 'calc(100% + 30px)',
    margin: '-15px',
    ['.MuiGrid-item']: {
      padding: '15px',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: 'calc(100% + 20px)',
    margin: '-10px',
    ['.MuiGrid-item']: {
      padding: '10px',
    },
  },
}))

const StatsRow = ({
  keyId,
  placements,
  coverage,
  professionals,
  dynamic,
  dynamicLabel,
}: Props) => {
  if (!dynamicLabel) {
    dynamicLabel = 'Officer Level Hires'
  }

  return (
    <StyledGrid
      container
      spacing={5}
      sx={{ alignItems: 'center', justifyContent: 'center' }}
    >
      <StatBox
        key={`${keyId}-placements`}
        value={placements}
        label="Placements"
      />
      <StatBox
        key={`${keyId}-coverage`}
        value={coverage}
        label="Years Of Coverage"
      />
      <StatBox
        key={`${keyId}-pros`}
        value={professionals}
        label="Professionals"
      />
      <StatBox key={`${keyId}-dynamic`} value={dynamic} label={dynamicLabel} />
    </StyledGrid>
  )
}

export default memo(StatsRow)
